import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Headerlogo from "../assests/images/Group13.png";
import VideoCall from "../assests/images/videoCall.png";
import BgVideo from "../assests/videos/bg.mp4";
import VideoChat from "../Components/VideoChat";
import SaudiLogo from "../Components/visitSaudiLogo";
import PageModal from "../Components/Modal/ParentModal";
import "../Theme/style.css";
import "../utills/i18n.jsx";
import { useIsMobile, checkIframeLoad } from "../utills/helpers";

const Home = () => {
  const [langauge, setlangauge] = useState("en");
  const [isHome, setIsHome] = useState(true);
  const { t, i18n } = useTranslation();
  const [isTouched, setiIsTouched] = useState(false);
  const [isStared, setIsStarted] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [counter, setCounter] = useState(0);
  const [iframeLoaded, setIframeLoded] = useState(false);
  const [toggle, setToggle] = useState(false);
  const handleModalOpen = () => {
    setOpenModal(true);
    handleStart();
  };
  const handleLoadedIframe = () => {
    setIframeLoded(true);
  };

  const handleModalClose = () => {
    handleStop();
    setOpenModal(false);
    setCounter(0);
  };
  const [currentItem, setCurrentItem] = useState({
    url: "",
    title: "",
  });
  const [childModalOpen, setChildModalOpen] = useState(false);
  const handleChildModalOpen = () => {
    setChildModalOpen(true);
  };
  const handleChildModalClose = () => {
    setChildModalOpen(false);
    handleStop();
  };

  const isMobile = useIsMobile(768);
  const isIpad = useIsMobile(1268);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params) {
      let queryStringValue = params.enable;

      if (queryStringValue === "false") {
        setIsEnabled(false);
      } else if (queryStringValue === "true") {
        setIsEnabled(true);
      } else {
        setIsEnabled(true);
      }
    }
    if (isTouched) {
      setTimeout(() => {
        setiIsTouched(false);
      }, 20000);
    }
  }, [isTouched]);

  useEffect(() => {
    if (openModal) {
      checkIframeLoad(handleLoadedIframe);
    }
    let timer;
    if (toggle && iframeLoaded) {
      timer = setInterval(() => {
        setCounter((counter) => counter + 1);
        if (counter > 18) {
          handleChildModalOpen();
        }
      }, 10000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [toggle, counter, openModal, iframeLoaded]);

  const handleStart = () => {
    setToggle(true);
  };
  const handleStop = () => {
    setToggle(false);
  };

  const Items = [
    {
      id: 1,
      Title: "home.MapTitle",
      SubTitle: "home.MapSubTitle",
      BottomSubTitle: "",
      Url: `https://map.visitsaudi.com/${langauge}`,
      TopIcon: <SaudiLogo language={langauge} />,
      MidIcon: null,
      className: "card1",
    },
    {
      id: 2,
      Title: "home.SaudiCalendarTitle",
      SubTitle: "home.SaudiCalendarSubTitle",
      BottomSubTitle: "",
      Url: `https://calendar.visitsaudi.com/${
        langauge === "en" ? "en" : ""
      }`,
            TopIcon: <SaudiLogo language={langauge} />,
      MidIcon: null,
      className: "card2",
    },
    {
      id: 3,
      Title: "home.BookingTitle",
      SubTitle: "home.BookingSubTitle",
      BottomSubTitle: null,
      Url: `https://book.visitsaudi.com/${ langauge === "en" ? "sa-en" : `sa-ar`}`,
      TopIcon: <SaudiLogo language={langauge} />,
      MidIcon: null,
      className: "card3",
    },
  ];

  let render;
  if (isHome) {
    render = (
      <div>
        <div className="mainTitle">{t("home.mainTitle")}</div>
        {!isTouched && (
          <div className="touch">
            <div className="tabIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="51.258"
                height="62.084"
                viewBox="0 0 51.258 62.084"
              >
                <path
                  id="icons8-natural_user_interface_2"
                  d="M26.587,2a10.779,10.779,0,0,0-5.4,20.118V42.954l-6.959-7A5.4,5.4,0,1,0,6.6,43.586l15.31,15.352a17.544,17.544,0,0,0,12.4,5.146h9.827A12.17,12.17,0,0,0,56.28,51.937V34.392a5.416,5.416,0,0,0-5.4-5.4,5.341,5.341,0,0,0-3.037.928,5.379,5.379,0,0,0-5.061-3.627,5.283,5.283,0,0,0-3.459,1.35,5.4,5.4,0,0,0-4.639-2.7,5.3,5.3,0,0,0-2.7.759V22.118A10.779,10.779,0,0,0,26.587,2Zm0,2.7a8.095,8.095,0,0,1,5.4,14.129V12.8a5.4,5.4,0,1,0-10.8,0v6.031A8.095,8.095,0,0,1,26.587,4.7Zm0,5.4a2.678,2.678,0,0,1,2.7,2.7v8.351a1.414,1.414,0,0,0,0,.422V33.042a1.353,1.353,0,0,0,1.645,1.35,1.368,1.368,0,0,0,.38-.169.63.63,0,0,0,.084-.042,1.33,1.33,0,0,0,.211-.211,1.349,1.349,0,0,0,.38-.928v-2.7a2.7,2.7,0,0,1,5.4,0v2.7a1.409,1.409,0,0,0,.042.253,1.239,1.239,0,0,0,.127.38,1.4,1.4,0,0,0,.211.337,1.287,1.287,0,0,0,.211.169c.042.032.084.058.127.084a1.239,1.239,0,0,0,.38.127,1.161,1.161,0,0,0,.928-.169.629.629,0,0,0,.084-.042,1.33,1.33,0,0,0,.211-.211,1.349,1.349,0,0,0,.38-.928v-1.35a2.7,2.7,0,0,1,5.4,0v4.049a1.35,1.35,0,1,0,2.7,0V34.6a1.408,1.408,0,0,0,0-.211,2.7,2.7,0,1,1,5.4,0V51.937a9.425,9.425,0,0,1-9.448,9.448H34.305a14.852,14.852,0,0,1-10.5-4.344L8.493,41.688a2.684,2.684,0,1,1,3.8-3.8l9.11,9.068a1.287,1.287,0,0,0,.169.211.638.638,0,0,0,.084.084,1.348,1.348,0,0,0,.633.3,1.372,1.372,0,0,0,.548,0,1.426,1.426,0,0,0,.253-.084c.042-.026.084-.053.127-.084a1.344,1.344,0,0,0,.675-1.054V46.2a.9.9,0,0,0,0-.169V21.486a1.414,1.414,0,0,0,0-.422V12.8A2.678,2.678,0,0,1,26.587,10.1Z"
                  transform="translate(-5.021 -2)"
                  fill="#efedf1"
                />
              </svg>
            </div>
            <div className="tabContent">{t("home.tapOn")}</div>
          </div>
        )}

        <div className="items">
          {Items.map((item, i) => (
            <div
              className={item.className}
              key={item.id}
              style={{
                cursor:
                  item.Url === "/lobby"
                    ? isEnabled
                      ? "pointer"
                      : "not-allowed"
                    : "pointer",
                transform:
                  item.Url === "/lobby" && langauge === "ar"
                    ? "scaleX(-1)"
                    : "",
              }}
              onClick={() => {
                if (item.Url === "/lobby") {
                  if (isEnabled) {
                    setIsHome(false);
                  }
                  return;
                } else {
                  // window.open(item.Url, "_blank");

                  setCurrentItem({
                    url: item.Url,
                    title: item.SubTitle,
                  });
                  handleModalOpen();
                }
              }}
            >
              <div
                className="cardData"
                style={{
                  transform:
                    item.Url === "/lobby" && langauge === "ar"
                      ? "scaleX(-1)"
                      : "",
                }}
              >
                {item.MidIcon !== null ? (
                  <div>
                    <div className="itemTitle">{t(item.Title)}</div>
                    <img
                      src={VideoCall}
                      alt="midIcon"
                      style={{
                        transform: langauge === "ar" ? "rotate(180deg)" : "",
                        marginBottom: isMobile ? "1rem" : "2rem",
                        width: isMobile ? "80.006px" : "",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{ marginRight: langauge === "ar" ? "-3rem" : "" }}
                  >
                    {item.TopIcon}
                  </div>
                )}
                {item.BottomSubTitle !== "" && (
                  <div className="BottomSubTitle">{t(item.BottomSubTitle)}</div>
                )}
                <div className="itemSubtitle">
                  {item.MidIcon === null && (
                    <div
                      style={{
                        fontSize: isIpad ? "16.994px" : "19.998px",
                      }}
                    >
                      {item.MidIcon === null && item.TopIcon === null ? (
                        <div className="nullIcons">{t(item.Title)}</div>
                      ) : (
                        <div className="NotNullIcons">{t(item.Title)}</div>
                      )}
                    </div>
                  )}

                  {t(item.SubTitle)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    render = (
      <VideoChat
        langauge={langauge}
        setIsHome={setIsHome}
        isHome={isHome}
        setIsStarted={setIsStarted}
      />
    );
  }
  return (
    <div>
      {!isMobile && !isStared && (
        <video className="bgVideo" autoPlay muted loop>
          <source src={BgVideo} type="video/mp4" />
        </video>
      )}
      {/* <video className="bgVideo" autoPlay muted loop>
        <source src={BgVideo} type="video/mp4" />
      </video> */}

      <div
        className={isMobile || isStared ? "HomePage" : "HomePageMob"}
        style={{
          direction: langauge === "en" ? "ltr" : "rtl",
          textAlign: langauge === "en" ? "left" : "right",
        }}
        onClick={() => {
          setiIsTouched(true);
        }}
      >
        <div className="header">
          <div className="logoDiv">
            <img
              src={Headerlogo}
              alt="Headerlogo"
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(`https://www.sta.gov.sa/${langauge}/`, "_blank");
              }}
            />
          </div>

          <div className="headerLang">
            <div
              className="GlobleIcon"
              onClick={() => {
                if (i18n.language === "en") {
                  i18n.changeLanguage("ar");
                  setlangauge("ar");
                } else {
                  i18n.changeLanguage("en");
                  setlangauge("en");
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                height="30"
              >
                <path
                  d="M14.5 15.3H17c-1 1.3-2.3 2.3-3.9 2.9.3-.4.6-.8.8-1.3.2-.5.4-1 .6-1.6zm-3.9 0h2.6c-.1.4-.3.7-.4 1-.6 1.2-1.4 2-2.1 2.3l-.1-3.3zm-3.8 0h2.6v3.3c-.8-.3-1.5-1.1-2.1-2.3-.2-.3-.4-.6-.5-1zm-3.8 0h2.4c.2.6.4 1.1.6 1.6.2.5.5.9.8 1.3-1.4-.6-2.8-1.6-3.8-2.9zm12.3-4.7h3.4c-.1 1.2-.4 2.4-1 3.4h-2.9c.3-1 .5-2.2.5-3.4zm-4.7 0H14c0 1.2-.2 2.4-.5 3.4h-2.9v-3.4zm-4.7 0h3.4V14H6.4c-.2-1-.4-2.1-.5-3.4zm-4.6 0h3.4c0 1.2.2 2.4.4 3.4H2.2c-.5-1-.8-2.1-.9-3.4zm13.6-4.7h2.9c.5 1 .9 2.2 1 3.4h-3.4c-.1-1.1-.3-2.3-.5-3.4zm-4.3 0h2.9c.3 1 .4 2.2.5 3.4h-3.4V5.9zm-4.2 0h2.9v3.4H5.9c.1-1.2.3-2.3.5-3.4zm-4.2 0h2.9c-.2 1.1-.4 2.3-.4 3.5H1.3c.1-1.3.4-2.4.9-3.5zm10.9-4.1c1.5.6 2.9 1.6 3.9 2.9h-2.4c-.2-.6-.4-1.1-.6-1.6-.4-.5-.6-.9-.9-1.3zm-6.2 0c-.3.4-.5.8-.8 1.3-.2.5-.5 1-.6 1.6H3c1-1.3 2.4-2.3 3.9-2.9zm3.7-.4c.8.3 1.5 1 2.1 2.3.2.3.3.7.4 1h-2.6l.1-3.3zm-1.2 0v3.3H6.8c.1-.4.3-.7.4-1 .7-1.3 1.4-2.1 2.2-2.3zM10 0C4.5 0 0 4.5 0 10c0 5.4 4.3 9.8 9.7 10h.6c5.4-.2 9.7-4.6 9.7-10 0-5.5-4.5-10-10-10z"
                  fill="white"
                ></path>
              </svg>
              <span className="IconSpan">{t("language")}</span>
            </div>
          </div>
        </div>
        {render}

        <PageModal
          openModal={openModal}
          handleModalClose={handleModalClose}
          handleModalOpen={handleModalOpen}
          currentItem={currentItem}
          childModalOpen={childModalOpen}
          handleChildModalOpen={handleChildModalOpen}
          handleChildModalClose={handleChildModalClose}
          langauge={langauge}
        />
      </div>
    </div>
  );
};

export default Home;
