import React, { Component } from "react";
import Participant from "./Participant";
import Share from "./Share";
import "../Theme/style.css";
import axios from "axios";
import FeedbackModal from "./Modal/FeedbackModal";

export default class Room extends Component {
  state = {
    remoteParticipants: Array.from(this.props.room.participants.values()),
    counterFinish: false,
    isJoined: false,
    interval: [],
    time: -1,
    IsSharing: false,
    logoutTimer: null,
    NumberOfUsers: 1,
    openModal: true,
  };

  componentDidMount() {
    // Add event listeners for future remote participants coming or going
    this.props.room.on("participantConnected", (participant) =>
      this.addParticipant(participant)
    );
    this.props.room.on("participantDisconnected", (participant) =>
      this.removeParticipant(participant)
    );

    window.addEventListener("beforeunload", this.leaveRoom);
  }

  handleModalOpen = () => {
    this.setState({
      openModal: true,
    });
  };

  handleModalClose = () => {
    this.setState({
      openModal: false,
    });
  };

  addParticipant(participant) {
    this.setState({
      remoteParticipants: [...this.state.remoteParticipants, participant],
      NumberOfUsers: this.state.NumberOfUsers + 1,
      isJoined: true,
      counterFinish: true,
    });
  }

  removeParticipant(participant) {
    this.setState({
      remoteParticipants: this.state.remoteParticipants.filter(
        (p) => p.identity !== participant.identity
      ),
      NumberOfUsers: this.state.NumberOfUsers - 1,
    });
  }

  componentWillMount() {
    var timeleft = 120;
    if (this.state.time === 0) {
      this.state.interval.forEach(clearInterval);
      this.setState({ counterFinish: true });
    } else {
      this.state.interval.push(
        setInterval(() => {
          if (this.state.time === 0) {
            this.state.interval.forEach(clearInterval);
            this.setState({ counterFinish: true });
          } else {
            timeleft--;
            if (document.getElementById("progressBar") !== null) {
              this.setState({ time: timeleft });
              document.getElementById("progressBar").value = timeleft;
            }
          }
        }, 1000)
      );
    }
  }
  componentWillUnmount() {
    this.state.interval.forEach(clearInterval);
    this.cancleTask();
  }

  StartShare = () => {
    this.setState({ IsSharing: true });
  };

  StopShare = () => {
    this.setState({ IsSharing: false });
  };

  leaveRoom = () => {
    if (!!this.state.logoutTimer) {
      clearInterval(this.state.logoutTimer);
    }
    if (this.state.time < 120 && !this.state.isJoined) {
      this.logOutAndCancleEvent();
    }
    this.setState({
      participants: [],
      isJoined: false,
      counterFinish: false,
      time: -1,
    });
    this.props.handleLogout();
  };

  logOutAndCancleEvent = async () => {
    await this.props.handleLogout();
  };

  cancleTask = () => {
    var roomName = this.props.roomName;
    axios
      .get(`https://general-2711.twil.io/CancelTask?videoChatRoom=${roomName}`, {
        headers: {
          'Access-Control-Allow-Origin' : '*',
        }
       })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidUpdate = (prevState) => {
    if (
      (this.state.counterFinish || this.state.isJoined) &&
      this.state.NumberOfUsers === 1
    ) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.logoutTimer = setTimeout(() => this.leaveRoom(), 60000);
    }
  };
  render() {
    let { room, translate, lang } = this.props;
    let {
      remoteParticipants,
      counterFinish,
      isJoined,
      IsSharing,
      NumberOfUsers,
    } = this.state;
    let { leaveRoom, StartShare, StopShare } = this;

    const remoteParticipant = remoteParticipants.map((participant) => (
      <Participant
        key={participant.sid}
        participant={participant}
        IsSharing={IsSharing}
        StartShare={StartShare}
        StopShare={StopShare}
      />
    ));

    const remoteParticipantsOnShare = remoteParticipants.map((participant) => (
      <Participant
        key={participant.sid}
        participant={participant}
        IsSharing={IsSharing}
        StartShare={StartShare}
        StopShare={StopShare}
      />
    ));
    const ShareVideo = remoteParticipants.map((participant) => (
      <Share
        key={participant.sid}
        participant={participant}
        IsSharing={IsSharing}
        StartShare={StartShare}
        StopShare={StopShare}
      />
    ));
    return (
      <div>
        {counterFinish && !isJoined ? (
          <div
            className="Lobbybody"
            style={{
              direction: lang === "ar" ? "rtl" : "ltr",
            }}
          >
            <div
              className="Roomform"
              style={{ height: lang === "ar" ? "" : "350px" }}
            >
              <form onSubmit={leaveRoom}>
                <div className="title">{translate("sorry")}</div>
                <div className="subtitle">
                  {translate("room.NotJoinedSubtitle")}
                </div>
                <button type="text" className="submit">
                  {translate("room.NotJoinedButton")}
                </button>
              </form>
            </div>
          </div>
        ) : (
          <div>
            {counterFinish && isJoined && NumberOfUsers !== 2 ? (
              <div
                className="Lobbybody"
                style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
              >
                <FeedbackModal
                  openModal={this.state.openModal}
                  handleModalClose={this.handleModalClose}
                  handleModalOpen={this.handleModalOpen}
                  url={"https://analytics-eu.clickdimensions.com/cn/aspph/videochat"}
                  langauge={lang}
                />
                <div className="Roomform">
                  <form onSubmit={leaveRoom}>
                    <div className="title">{translate("room.JoinedTitle")}</div>
                    <div className="subtitle">
                      {translate("room.JoinedSubtitle")}
                    </div>
                    <button type="text" className="submit">
                      {translate("room.JoinedButton")}
                    </button>
                  </form>
                </div>
              </div>
            ) : (
              <div
                className="mainDiv"
                style={{
                  direction: lang === "ar" ? "rtl" : "ltr",
                  textAlign: "center",
                }}
              >
                <div className="Counter-info">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="60"
                      fill="rgb(2, 195, 154)"
                      className="bi bi-people-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      <path
                        fillRule="evenodd"
                        d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                      />
                      <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                    </svg>
                    <span className="badge badge-warning" id="lblCartCount">
                      {NumberOfUsers}
                    </span>
                  </div>
                  {remoteParticipants.length !== 0 ? (
                    ""
                  ) : (
                    <div>
                      <div className="Waiting">
                        {translate("room.WaitingTime")}
                      </div>
                      <progress value="0" max="120" id="progressBar"></progress>
                    </div>
                  )}
                </div>
                {remoteParticipants.length !== 0 && IsSharing ? (
                  <div className="OnShare">
                    <div>
                      <div className="video">{ShareVideo}</div>
                    </div>
                    <div>
                      <div className="video">
                        <div className="margin">
                          {room ? (
                            <Participant
                              key={room.localParticipant.sid}
                              participant={room.localParticipant}
                              IsSharing={IsSharing}
                              StartShare={StartShare}
                              StopShare={StopShare}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="video"> {remoteParticipantsOnShare}</div>
                    </div>
                  </div>
                ) : (
                  <div className="RoomMain">
                    <div className="local-participant">
                      {room ? (
                        <Participant
                          key={room.localParticipant.sid}
                          participant={room.localParticipant}
                          IsSharing={IsSharing}
                          StartShare={StartShare}
                          StopShare={StopShare}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {remoteParticipants.length !== 0 ? (
                      <div>
                        <div className="remote-participants">
                          {remoteParticipant}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                <div className="roomButton">
                  <button className="button" onClick={leaveRoom}>
                    <span>{translate("room.LeaveButton")} </span>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
