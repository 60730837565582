import { useState, useEffect } from "react";
export const useIsMobile = (customWidth) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth <= (customWidth || 768)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  return isMobile;
};

export const checkIframeLoad = function (set) {
  let frame = document.getElementById("iframe");
  frame.addEventListener("load", set);
};
