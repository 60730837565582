import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Headerlogo from "../../assests/images/Group13.png";
import "../../utills/i18n.jsx";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20%",
  bgcolor: "#3FA796", 
  border: "2px solid #000",
  color: "#ffffff",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexWrap: "wrap",
  fontSize: "1rem",
  flexDirection: "row",
  alignContent: "center",
  justifyContent: "space-around",
};

export default function ChildModal(props) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Modal
        hideBackdrop
        open={props.childModalOpen}
        onClose={props.handleChildModalClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className="child-modal" sx={{ ...style, width: "20%" }}>
          <img alt="logo" width={"60%"} height="60%" src={Headerlogo} />
          <h2
            id={
              props.langauge === "ar"
                ? "child-modal-title-ar"
                : "child-modal-title"
            }
          >
            {t("modal.prompt")}
          </h2>

          <div
            className={
              props.langauge === "ar"
                ? "button-container-ar"
                : "button-container"
            }
          >
            <Button
              className={
                props.langauge === "ar" ? "modal-button-ar" : "modal-button"
              }
              onClick={function () {
                props.handleChildModalClose();
                props.handleModalClose();
              }}
            >
              {t("modal.yesAnswer")}
            </Button>
            <Button
              className={
                props.langauge === "ar" ? "modal-button-ar" : "modal-button"
              }
              onClick={props.handleChildModalClose}
            >
              {t("modal.noAnswer")}
            </Button>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
