import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          modal: {
            prompt: "Would you like to close this page?",
            yesAnswer: "Yes",
            noAnswer: "No",
          },
          home: {
            mainTitle: "Welcome to Saudi",
            SaudiCalendarTitle: "Upcoming..",
            WorldCupTitle: "World Cup Fans Journey Guide",
            SaudiTitle: "Everything About Saudi",
            ChatTitle: "Need Help?",
            SaudiCalendarSubTitle: "Saudi Calendar",
            SaudiSubTitle: "Visit Saudi",
            WorldCupSubTitle: "#HereForYou",
            ChatSubTitle: "Tap for a Video Call",
            // ChatSubTitle: "Coming Soon",
            goBack: "Back to home screen",
            tapOn: "Tap on the touchscreen",
            ChatBottomSubTitle: "Video Calls",
            MapTitle:"Where to go?",
            MapSubTitle:"Explore Saudi Arabia",
            BookingTitle:"It’s all happening..",
            BookingSubTitle:"Book Experiences"
          },
          lobby: {
            RequiredFName: "First Name is required",
            RequiredLName: "Last Name is required",
            OnlyLetters: "Only letters are permitted",
            RequiredEmail: "Email is required",
            NotValidEmail: "Email is not valid",
            Title: "Welcome",
            Subtitle: "Let's start the video call",
            InputFname: "First Name",
            InputLname: "Last Name",
            InputEmail: "Email",
            ButtonConnecting: "Connecting",
            ButtonStart: "Start video call with Visitor Care",
            Content:
              "Unfortunately, all of our agents are currently engaged in assisting other visitors. Please try contacting us through the communication channels provided.",
            riyadhSeason: "Riyadh Season",
            visitSaudi: "Visit Saudi",
            here: "here.",
          },
          room: {
            NotJoinedSubtitle:
              "Unfortunately, all of our agents are currently engaged in assisting other visitors. You will be redirected to the home page in 60 seconds. Please try contacting us through the communication channels provided.",
            NotJoinedButton: "Start another Video Call",
            JoinedTitle: "Thank you!",
            JoinedSubtitle:
              "Thank you for contacting Visitor Care and for the call. You will be redirected to home page in 60 seconds.",
            JoinedButton: "Start a new video call",
            WaitingTime: "Waiting time",
            LeaveButton: "End the video call",
          },
          sorry: "Thank you for contacting Visitor Care",
          language: "العربية",
        },
      },
      ar: {
        translation: {
          modal: {
            prompt: "هل تريد العودة الى الصفحة الرئيسية؟",
            yesAnswer: "نعم",
            noAnswer: "لا",
          },
          home: {
            mainTitle: "مرحبا بك في السعودية",
            SaudiCalendarTitle: "الفعاليات القادمة",
            SaudiSubTitle: "تقويم الفعاليات",
            ChatSubTitle: "أنقر هنا",
            // ChatSubTitle: "قريباً...",
            SaudiCalendarSubTitle: "تقويم الفعاليات",
            SaudiTitle: "كل شيء عن روح السعودية",
            ChatTitle: "تحتاج مساعدة؟",
            WorldCupTitle: "تعرف على تفاصيل و إجراءات رحلتك",
            WorldCupSubTitle: "#حاضرين",
            goBack: "العودة للرئيسية",
            tapOn: "اضغط هنا للبدء",
            ChatBottomSubTitle: "مكالمات الفيديو",
            MapTitle:"أين وجهتك القادمة؟",
            MapSubTitle:"اكتشف السعودية",
            BookingTitle:"حان الوقت..",
            BookingSubTitle:"احجز أفضل التجارب السياحية"
          },
          lobby: {
            RequiredFName: "يرجى تعبئة الاسم الأول",
            RequiredLName: "يرجى تعبئة الاسم الأخير",
            OnlyLetters: "حروف فقط!",
            RequiredEmail: "يرجى تعبئة عنوان البريد الالكتروني",
            NotValidEmail: "عنوان البريد الالكتروني غير صحيح",
            Title: "أهلاً و سهلاً",
            Subtitle: "لنبدأ مكالمة الفيديو الخاصة بك!",
            InputFname: "الاسم الأول",
            InputLname: "الاسم الأخير",
            InputPhone: "رقم الهاتف",
            InputEmail: "البريد الإلكتروني",
            ButtonConnecting: "جاري الأتصال",
            ButtonStart: "ابدأ مكالمة فيديو مع رعاية الزوار",
            Content:
              "لسوء الحظ، فإن جميع عملائنا مشغولون حاليًا في مساعدة الزائرين الآخرين ، لذا يرجى محاولة الاتصال بنا من خلال قنوات التواصل المتوفرة ",
            riyadhSeason: "موسم الرياض",
            visitSaudi: "روح السعودية",
            here: "هنا.",
          },
          room: {
            NotJoinedSubtitle:
              "لسوء الحظ، فإن جميع عملائنا مشغولون حاليًا في مساعدة الزائرين الآخرين ، لذا يرجى محاولة الاتصال بنا من خلال قنوات التواصل المتوفرة ",
            NotJoinedButton: "ابدأ مكالمة فيديو جديدة",
            JoinedTitle: "شكرًا على اتصالك بفريق دعم روح السعودية",
            JoinedSubtitle:
              "شكرًا على اتصالك بفريق دعم روح السعودية ،إنتهت المكالمة. ستتم إعادة توجيهك إلى صفحة انشاء مكالمة الفيديو خلال 60 ثانية.",
            JoinedButton: "ابدأ مكالمة فيديو جديدة",
            WaitingTime: "وقت الأنتظار",
            LeaveButton: "إنهاء مكالمة الفيديو",
          },

          sorry: "شكرًا على اتصالك بفريق دعم روح السعودية",
          language: "English",
        },
      },
    },
  });

export default i18n;
