import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Modal from "@mui/material/Modal";
import "../../utills/i18n.jsx";
export default function FeedbackModal(props) {

  setTimeout(()=>{
    props?.handleModalClose()
  },120000)

  return (
    <div>
      <Modal
        open={props?.openModal}
        onClose={props?.handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        keepMounted={true}
      >
        <Box className="feedback-modal">
          <div
            className={
              props?.langauge === "ar"
                ? "modal-header-container-ar"
                : "modal-header-container"
            }
          >
  
            <IconButton
              className="close-button"
              size="large"
              onClick={props?.handleModalClose}
            >
              <CancelIcon className="close-icon"></CancelIcon>
            </IconButton>
          </div>
          <iframe
            id="iframe"
            title="modal"
            src={`${props?.url}`}
            width={"100%"}
            height={"100%"}
            style={{ borderRadius: ".5vw" }}
          ></iframe>

        </Box>
      </Modal>
    </div>
  );
}
