import React, { Component } from "react";
import "../Theme/style.css";
import axios from "axios";

export default class Lobby extends Component {
  state = {
    fields: {
      Fname: "",
      Lname: "",
      email: "",
    },
    errors: {},
    FromValid: false,
    AccId: "",
    AccSecret: "",
    language: "en",
    isMobile: false,
  };

  getdata = async () => {
    
  };
  componentWillMount() {
    this.getdata();
  }

  componentDidMount() {
    this.setState({ isMobile: window.innerWidth <= 1245 });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      language: nextProps.i18n.language,
      errors: {},
      FromValid: false,
    });
  }
  createTask = async (event) => {
    event.preventDefault();
    await this.handleValidation();
    if (!this.state.FromValid) {
      return;
    }
    let accountID = this.state.AccId;
    let AccSecret = this.state.AccSecret;
    this.props.getCreaditials(accountID, AccSecret);
    this.props.handleSubmit();
  };

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let isFromValid = true;

    //FName
    if (fields["Fname"] === "") {
      isFromValid = false;
      errors["Fname"] = this.props.translate("lobby.RequiredFName");
    } else {
      if (typeof fields["Fname"] !== "undefined") {
        if (
          !fields["Fname"].match(
            /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_ ]*$/
          )
        ) {
          isFromValid = false;
          errors["Fname"] = this.props.translate("lobby.OnlyLetters");
        }
      }
    }

    //LName
    if (fields["Lname"] === "") {
      isFromValid = false;
      errors["Lname"] = this.props.translate("lobby.RequiredLName");
    } else {
      if (typeof fields["Lname"] !== "undefined") {
        if (
          !fields["Lname"].match(
            /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_ ]*$/
          )
        ) {
          isFromValid = false;
          errors["Lname"] = this.props.translate("lobby.OnlyLetters");
        }
      }
    }

    //Email
    if (fields["email"] === "") {
      isFromValid = false;
      errors["email"] = this.props.translate("lobby.RequiredEmail");
    } else {
      if (typeof fields["email"] !== "undefined") {
        let lastAtPos = fields["email"].lastIndexOf("@");
        let lastDotPos = fields["email"].lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields["email"].indexOf("@@") === -1 &&
            lastDotPos > 2 &&
            fields["email"].length - lastDotPos > 2
          )
        ) {
          isFromValid = false;
          errors["email"] = this.props.translate("lobby.NotValidEmail");
        }
      }
    }

    this.setState({ errors: errors, FromValid: isFromValid });
  }

  handlekeyressChangeFName(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;

    var timetick = new Date().getTime();

    var Fname = e.target.value;
    this.props.handleUserFnameChange(Fname);

    let roomName = e.target.value + " - " + timetick;
    this.props.handleRoomNameChange(roomName);

    this.setState({ fields: fields, errors: {} });
  }

  handlekeyressChangeEmail(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;

    let email = e.target.value;
    this.props.handleEmailChange(email);

    this.setState({ fields: fields, errors: {} });
  }

  handlekeyressChangeLname(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;

    let LName = e.target.value;
    this.props.handleUserLnameChange(LName);

    this.setState({ fields: fields, errors: {} });
  }

  render() {
    const {
      connecting,
      IsAuthError,
      handleIsAuthError,
      translate,
      setIsHome,
      isHome,
    } = this.props;
    const { fields, errors, language } = this.state;
    const {
      handlekeyressChangeFName,
      handlekeyressChangeLname,
      handlekeyressChangeEmail,
      createTask,
    } = this;
    return (
      <div >
        <div
          className="Lobbybody"
        >
          <div className="form">
            <form onSubmit={createTask}>
              <div className="title">{translate("lobby.Title")}</div>
              <div className="input-container ic1">
                <input
                  id="Fname"
                  className="input"
                  useref="Fname"
                  type="text"
                  placeholder=" "
                  onChange={handlekeyressChangeFName.bind(this, "Fname")}
                  value={fields["Fname"]}
                />
                <div
                  className="cut"
                  style={{
                    left: language === "en" ? "20px" : "unset",
                    right: language === "en" ? "unset" : "20px",
                    width: language === "en" ? "92px" : "77px",
                  }}
                ></div>

                <label
                  htmlFor="Fname"
                  className="placeholder"
                  style={{
                    left: language === "en" ? "20px" : "unset",
                    right: language === "en" ? "unset" : "20px",
                  }}
                >
                  {translate("lobby.InputFname")}
                </label>
                <div className="error" style={{ color: "red" }}>
                  {errors["Fname"]}
                </div>
              </div>
              <div className="input-container ic2">
                <input
                  id="Lname"
                  className="input"
                  type="text"
                  placeholder=" "
                  onChange={handlekeyressChangeLname.bind(this, "Lname")}
                  value={fields["Lname"]}
                />
                <div
                  className="cut"
                  style={{
                    left: language === "en" ? "20px" : "unset",
                    right: language === "en" ? "unset" : "20px",
                  }}
                ></div>
                <label
                  htmlFor="Lname"
                  className="placeholder"
                  style={{
                    left: language === "en" ? "20px" : "unset",
                    right: language === "en" ? "unset" : "20px",
                  }}
                >
                  {translate("lobby.InputLname")}
                </label>
                <div className="error" style={{ color: "red" }}>
                  {errors["Lname"]}
                </div>
              </div>
              <div className="input-container ic2">
                <input
                  id="email"
                  className="input"
                  type="text"
                  placeholder=" "
                  useref="email"
                  size="30"
                  onChange={handlekeyressChangeEmail.bind(this, "email")}
                  value={fields["email"]}
                />
                <div className="error" style={{ color: "red" }}>
                  {errors["email"]}
                </div>
                <div
                  className="cut cut-short"
                  style={{
                    left: language === "en" ? "20px" : "unset",
                    right: language === "en" ? "unset" : "20px",
                    width: language === "en" ? "60px" : "114px",
                  }}
                ></div>
                <label
                  htmlFor="email"
                  className="placeholder"
                  style={{
                    left: language === "en" ? "20px" : "unset",
                    right: language === "en" ? "unset" : "20px",
                  }}
                >
                  {translate("lobby.InputEmail")}
                </label>
              </div>
              <button type="text" className="submit" disabled={connecting}>
                {connecting
                  ? translate("lobby.ButtonConnecting")
                  : translate("lobby.ButtonStart")}
              </button>
              {!isHome && (
                <div
                  className="back"
                  onClick={() => {
                    setIsHome(true);
                  }}
                >
                  {translate("home.goBack")}
                </div>
              )}
            </form>
          </div>
        </div>
        {IsAuthError ? (
          <div id="popup1" className="overlay">
            <div className="popup">
              <button
                className="close"
                onClick={() => {
                  handleIsAuthError(false);
                }}
              >
                &times;
              </button>
              <h2>{translate("sorry")}</h2>
              <div className="content">
                {translate("lobby.Content")}
                <a
                  href={`https://help.visitsaudi.com/${language}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {translate("lobby.here")}
                </a>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
