import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Modal from "@mui/material/Modal";
import ChildModal from "./ChildModal";
import "../../utills/i18n.jsx";

export default function PageModal(props) {
  return (
    <div>
      <Modal
        open={props.openModal}
        onClose={props.handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        keepMounted={true}
      >
        <Box className="parent-modal">
          <IconButton
            className={
              props.langauge === "ar" ? "close-button-ar" : "close-button"
            }
            size="large"
            onClick={props.handleModalClose}
          >
            <CancelIcon
              style={{ fontSize: 32 }}
              className="close-icon"
            ></CancelIcon>
          </IconButton>
          <iframe
            id="iframe"
            title="modal"
            src={`${props.currentItem.url}`}
            width={"100%"}
            height={"100%"}
            style={{ borderRadius: ".5vw" }}
          ></iframe>
          
          <ChildModal
            childModalOpen={props.childModalOpen}
            handleModalClose={props.handleModalClose}
            handleChildModalOpen={props.handleChildModalOpen}
            handleChildModalClose={props.handleChildModalClose}
            langauge={props.langauge}
          />
        </Box>
      </Modal>
    </div>
  );
}
