import axios from "axios";
import React, { useState, useCallback, useEffect } from "react";
import Video from "twilio-video";
import Lobby from "./Lobby";
import Room from "./Room";
import { useTranslation } from "react-i18next";
import FeedbackModal from "./Modal/FeedbackModal";
import "../utills/i18n.jsx";

const VideoChat = ({ language, isHome, setIsHome, setIsStarted }) => {
  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [roomName, setRoomName] = useState("");
  const [email, setEmail] = useState("");
  const [room, setRoom] = useState(null);
  const [connecting, setConnecting] = useState(false);
  const [IsAuthError, setIsAuthError] = useState(false);
  const [IsApiError, setIsApiError] = useState(false);
  const [accountID, setAccountID] = useState("");
  const [AccSecret, setAccSecret] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  
  const { t, i18n } = useTranslation();
  const lngs = {
    en: { nativeName: "En" },
    ar: { nativeName: "Ar" },
  };

  const handleUserFnameChange = (Fname) => {
    setFname(Fname);
  };
  const handleUserLnameChange = (Lname) => {
    setLname(Lname);
  };

  const handleRoomNameChange = (roomName) => {
    setRoomName(roomName);
  };
  const handleEmailChange = (email) => {
    setEmail(email);
  };

  const handleIsAuthError = (IsAuthError) => {
    setIsAuthError(IsAuthError);
  };

  const handleIsApiError = (IsApiError) => {
    setIsApiError(IsApiError);
  };

  const getCreaditials = (accountID, AccSecret) => {
    setAccountID(accountID);
    setAccSecret(AccSecret);
  };

  const handleModalOpen = () => {
    setShowFeedbackModal(true);
  };

  const handleModalClose = () => {
    setShowFeedbackModal(false);
  };

  const handleSubmit = useCallback(async () => {
    setConnecting(true);

    const data = await axios.get(
      `https://general-2711.twil.io/GetPendingTasks`
    );

    if (data.data.queueAvailable) {
      const data = await axios.get(
        `https://general-2711.twil.io/flexvideotokenizer?identity=${roomName}&userIdentity=${email}`
      );
      Video.connect(data.data.token, {
        name: roomName,
      })
        .then((room) => {
          console.log(room);
          const formData = new FormData();
          formData.append("To", "Agent");
          formData.append("From", email);
          formData.append("videoChatRoom",roomName);
          formData.append("email",email);
          formData.append('username',Fname+ " " + Lname);
         /* formData.append(
            "Parameters",
            `{"videoChatRoom":"${roomName}" , "Email":"${email}" , "Phone":"" , "UserName":"${Fname + " " + Lname
            }"}`
          );*/
          console.log("****");
          console.log(formData);
          axios({method: "post",
          url: "/twilioExecutions",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" }}).then((response) => {
                console.log(response);
            }); 

          setConnecting(false);
          setIsStarted(true);
          setRoom(room);
        })
        .catch((err) => {
          setIsApiError(true);
          setConnecting(false);
        });
    } else {
      setConnecting(false);
      setIsAuthError(true);
    }
  }, [roomName, Fname, email, accountID, AccSecret, setIsStarted, Lname]);

  const handleLogout = useCallback(() => {
    setIsStarted(false);
    setRoom((prevRoom) => {
      if (prevRoom) {
        if (prevRoom?.participants?.size>0) {
          setShowFeedbackModal(true);
        }
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
      }
      return null;
    });
  }, [setIsStarted]);

  useEffect(() => {
    if (room) {
      const tidyUp = (event) => {
        if (event.persisted) {
          return;
        }
        if (room) {
          handleLogout();
        }
      };
      window.addEventListener("pagehide", tidyUp);
      window.addEventListener("beforeunload", tidyUp);
      return () => {
        window.removeEventListener("pagehide", tidyUp);
        window.removeEventListener("beforeunload", tidyUp);
      };
    }
  }, [room, handleLogout]);

  let render;
  if (room) {
    render = (
      <Room
        roomName={roomName}
        room={room}
        handleLogout={handleLogout}
        translate={t}
        lngs={lngs}
        i18n={i18n}
        lang={language}
      />
    );
  } else {
    render = (
      <>
        <FeedbackModal
          openModal={showFeedbackModal}
          handleModalClose={handleModalClose}
          handleModalOpen={handleModalOpen}
          url={"https://analytics-eu.clickdimensions.com/cn/aspph/videochat"}
        />
        <Lobby
          Fname={Fname}
          roomName={roomName}
          handleUserFnameChange={handleUserFnameChange}
          handleUserLnameChange={handleUserLnameChange}
          handleRoomNameChange={handleRoomNameChange}
          handleEmailChange={handleEmailChange}
          handleSubmit={handleSubmit}
          connecting={connecting}
          IsAuthError={IsAuthError}
          IsApiError={IsApiError}
          getCreaditials={getCreaditials}
          handleIsAuthError={handleIsAuthError}
          handleIsApiError={handleIsApiError}
          translate={t}
          lngs={lngs}
          i18n={i18n}
          isHome={isHome}
          setIsHome={setIsHome}
        />
      </>
    );
  }
  return render;
};

export default VideoChat;
